section.home {
  display: grid;
  grid-template-areas:
    "image"
    "info";
  margin: 0px 5px;
  padding: 0px 5px;
}

.home .image {
  grid-area: image;
  padding: 25px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
}

.home .image img {
  border-radius: 50%;
  width: 100%;
  max-width: 450px;
  max-height: 450px;
}

.home .home-info {
  grid-area: info;
  margin: auto 2px;
}

/* ---Media Queries for tablet Screens */
@media only screen and (min-width: 768px) {
  .home .pagePhoto {
    display: none;
  }
}

@media only screen and (min-width: 992px) {
  section.home {
    grid-template-areas: "image info";
    grid-template-columns: 500px auto;
    gap: 10px;
  }
}

@media only screen and (min-width: 1000px) {
  section.home {
    grid-template-areas: "blank image info blank2";
    grid-template-columns: auto 500px 500px auto;
  }
}
