footer {
  background: linear-gradient(rgba(0, 0, 0, 0.445),rgba(0, 0, 0, 0.4)), hsl(0, 0%, 12%);
  display: grid;
  grid-template-areas:
  "Author Author"
  "Email SocialMedia"
  ;
  grid-template-columns: auto 180px;
}

#authorNote {
  grid-area: Author;
}

#contactEmail {
  grid-area: Email;
}

footer ul#contactEmail {
  list-style: none;
}

footer ul#contactEmail, .smFooter {
  margin: 0px;
  padding: 5px 0px 10px 0px;
}

li.bold {
  font-weight: bold;
}

/* ---Media Queries for tablet Screens */
@media only screen and (min-width: 768px) {
  /* --- General Styles --- */
  footer{

    grid-template-areas:
      "Author Email SocialMedia"
    ;
    grid-template-columns: auto 150px 180px;
  }

  footer #authorNote {
    margin: 0px;
    padding: 5px 0px 10px 0px;
  }
}
