/* ---Media Queries for tablet Screens */
@media only screen and (min-width: 776px) {
  /* --- General Styles --- */

  /* --- Grid & Flex Style --- */
  ul.site-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(375px, auto));
    gap: 10px
  }

  /* --- Page Content --- */

  /* --- Footer Style --- */
}
