@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;600;800&family=Roboto:wght@400;700&display=swap");

/* --- Header Management --- */
header {
  font-family: 'Baloo 2';
  font-weight: 800;
  text-align: center;
  margin: 0px;
  padding-bottom: 5px;
  color: hsl(60, 100%, 98%);
}

header h1 {
  font-family: 'Baloo 2';
  font-weight: 800;
  font-size: 3rem;
  margin-top: 10px;
  margin-bottom: 0px;

}

header h2 {
  font-family: 'Baloo 2';
  font-weight: 800;
  margin-top: 2px;
  /* color:hsl(17, 86%, 41%) */
}

@media only screen and (min-width: 601px) {
  /* --- General Styles --- */
  header .mobileview {
    display: none;
  }
}

@media only screen and (max-width: 450px) {
  header .desktopview {
    display: none;
  }
}
