.social-media-bar {
  text-align: right;
}

div.pagePhoto {

  display: block;
  width: 180px;
  margin: 0px auto;
}

ul.social-media-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, 45px);
  list-style: none;
  margin: 0px;

}
