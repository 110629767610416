li.task-item {
  border: 1px solid rgb(61, 0, 0);
  border-radius: 10px;
  padding: 10px;
  width: 350px;
  height: 425px;
  display: block;
  margin: 20px auto;
  list-style: none;
  box-shadow: 0px 5px 10px hsl(238, 29%, 16%);
  background: hsl(0, 5%, 20%);
}

.siteImages {
  border: 1px solid black;
  width: 301px;
  height: 201px;
  display: block;
  text-align: center;
  border-radius: 5px;
  margin: auto;
}

.info {
  display: grid;
  grid-template-columns: auto 75px 75px;
}

.info .siteImages, .info p{
  grid-column: 1/4;
}

.info a:link {
  grid-column: 2/3;
  padding: 2px 5px;
  border-radius: 5px;
  border: 1px purple dotted;
  background-color: rgb(53, 21, 53);
  text-align: center;
  margin: 0px 2.5px;
}

.info a:link:last-child {
  grid-column: 3/4;
}

@media only screen and (min-width: 425px) {
  li.task-item {
    width: 375px
  }
}
