/* --- Nav management --- */
nav {
  padding: 10px 20px;
  width: 100%;
  background: hsl(0, 0%, 18%);
  margin: 10px auto 0px 0px;
}

nav a {
  margin-top: 2px;
  padding: 10px 20px;
  text-decoration: none;
  color:hsl(0, 3%, 57%)
}

/* nav a:visited {
  color: blue;
} */

.is-active {
  color: hsl(60, 100%, 98%);
  background: hsl(0, 0%, 12%);

}

nav div.navbar {
  display: none;
}

/* ---Media Queries for tablet Screens */
@media only screen and (min-width: 768px) {
  nav {
    display: grid;
    grid-template-columns: repeat(3, fit-content(150px)) auto 180px;
    padding-bottom: 0px;
    padding-top: 2px;
  }

  nav div.navbar {
    display: block;
    grid-column: 5/6;
    margin: 2px 0px;
    padding: 5px 0px
  }
}

@media only screen and (min-width: 776px) {
  nav {
    margin: 10px auto 0px 0px;
  }
}
