li.experience-card {
  display:grid;
  grid-template-areas:
    "title"
    "period"
    "company"
    "location"
    "description"
    "description"
  ;
  padding: 5px;
  margin: 5px 10px;
  border-radius: 5px;
  background-color: hsl(0, 5%, 20%);
}

li.experience-card .experience-title {
  grid-area: title;
  margin-top: 5px;
  margin-bottom: 2.5px;
}

li.experience-card .experience-location {
  grid-area: location;
  margin: 2.5px;
}

li.experience-card .experience-company {
  grid-area: company;
  margin: 2.5px;
}

li.experience-card .experience-period {
  grid-area: period;
  margin: 2.5px;
}

li.experience-card .experience-description {
  grid-area: description;
  margin: 2.5px;
}

li.experience-card .experience-description {
  list-style:inside;
  width: 95%;
}

/* ---Media Queries for tablet Screens */
@media only screen and (min-width: 768px) {
  /* --- General Styles --- */
  li.experience-card {
    grid-template-areas:
    "title company"
    "period location"
    "description description"
    "description description"
  ;
  }

  li.experience-card .experience-location, li.experience-card .experience-company {
    text-align: right;
    vertical-align: bottom;
  }

  li.experience-card .experience-company {

    margin-top: 5px;
    margin-bottom: 2.5px;
  }


}
