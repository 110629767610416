@charset "UTF-8";

/* --- CSS Document --- */
@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;600;800&family=Roboto:wght@400;700&display=swap");

/* --- Global Styles --- */
* {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  box-sizing: border-box;
  color: hsl(60, 100%, 98%);
}

/* --- General Styles --- */
body {
  margin: 0px;
  min-width: 320px;
  background-color: #111111;
}

#background {

  /* background-image: linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)) , url(EauDp1hUcAAE-bK.jpg); */
  /* background: linear-gradient(rgba(0, 0, 0, 0.445),rgba(0, 0, 0, 0.4)); */
  background-image: linear-gradient(rgba(0, 0, 0, 0.445),rgba(0, 0, 0, 0.4)), url('../img/mainbody-mobile.png');
  background-size: contain;
  background-repeat: no-repeat;
}

#root {
  display: grid;
  grid-template-rows: auto auto auto fit-content(2.6rem);

}

#root main {
  min-height: 78vh;
  background: hsl(0, 0%, 12%);
  padding: 5px;
}


/* --- Page Content --- */
ul {
  padding: 0px;
}

/* ---Media Queries for tablet Screens */
@media only screen and (min-width: 425px) {
  /* --- General Styles --- */
  #background {
    background-size: contain;
    background-image: linear-gradient(rgba(0, 0, 0, 0.445),rgba(0, 0, 0, 0.4)), url('../img/mainbody-tablet.png');
  }

}

/* ---Media Queries for tablet Screens */
@media only screen and (min-width: 768px) {
  /* --- General Styles --- */

}

/* --- Media Query for large tables or small desktops */
@media only screen and (min-width: 992px) {
  /* --- General Styles --- */
  #background {
    background-position: center;
    background-size: contain;
    background-repeat: repeat-y;
    background-image: linear-gradient(rgba(0, 0, 0, 0.445),rgba(0, 0, 0, 0.4)), url('../img/mainbody.png');
  }



}

/* --- Media Query for desktops */
@media only screen and (min-width: 1200px) {
  /* --- General Styles --- */
  #background {
    display: grid;
    grid-template-columns: auto 1200px auto;
  }

  #root {
    grid-column: 2/3;
    /* background: linear-gradient(rgba(0, 0, 0, 0.445),rgba(0, 0, 0, 0.4)), hsl(0, 0%, 12%); */
  }

}

@media only screen and (min-width: 1540px) {
  /* --- General Styles --- */

  /* --- Header Management --- */

  /* --- Nav management --- */

  /* --- Grid & Flex Style --- */


  /* --- Page Content --- */

  /* --- Footer Style --- */

}

