div.imageOverLap {
  display: grid;
  grid-template-columns: repeat(30, 10px);
  grid-template-rows: repeat(20, 10px);
  height: 100%;
  width: 100%;

}

.mobileImage {
  grid-row: 2/20;
  z-index: 0;
  height: 100%;
  width: auto;
}

.desktopImage {
  grid-column: 5/30;
  grid-row-start:3;
  z-index: 1;
  height: auto;
  width: 100%;
}
